import React from 'react';
import {Button, H2, Text} from "@blueprintjs/core"
import bgVideo from '../static/IC_Website.mp4';


function HomeView(props) {
    // Declare a new state variable, which we'll call "mouseOver"
    

  return (
    
    // <div>
    //     <video className="background-video" loop autoPlay>
    //     <source src={bgVideo} type="video/mp4"/>
    //     Your browser does not support the video tag.
    //     </video>
    <div>
    {/* <div style={{position:"relative",marginTop:"-200px"}}> */}

      
      <H2>Visual analytics using Instant Clue</H2>

      <Text>Instant Clue helps scientists to gain insights from their complex data.</Text>
      
      <Button style = {{marginTop:"40px"}}text = "Try it now" intent = "success" onClick = {() => props.setMainView("download")}/>
     
      <video className="background-video" loop autoPlay>
          <source src={bgVideo} type="video/mp4"/>
           Your browser does not support the video tag.
      </video>
      </div>
    // </div>


    
  );
}

export default HomeView;



