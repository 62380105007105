import React from 'react';
import {useState} from 'react';

import {AddFeatureRequestMail, ReportBug, AddGithubFeature} from "./SVGs"
import {motion} from "framer-motion"
import { H3 , Text} from '@blueprintjs/core';
import { openURL } from './utils/Misc';
const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }
const HOVER_TEXT = {addFeature:"Feature request (e-mail)",bugReport:"Report a bug on GitHub",addFeatureGithub:"Discuss/Request Features on GitHub Discussions"}

const DOWNLOAD_COMPS = {
    "addFeature" : AddFeatureRequestMail,
    "addFeatureGithub" : AddGithubFeature,
    "bugReport" : ReportBug,
  }
const LOGOS_FOR_DOWNLOAD = [{key:"addFeature"},{key:"addFeatureGithub"},{key:"bugReport"}]
const LINKS_FOR_KEYS = {bugReport:"https://github.com/hnolcol/instantclue/issues",addFeatureGithub:"https://github.com/hnolCol/instantclue/discussions"}

function openEmailWindow(){
  window.location.href = `mailto:h.nolte@age.mpg.de?subject=InstantClue_Request`;
}


function ContactView(props) {
  const [targetIcon, setTargetIcon] = useState("");
    // Declare a new state variable, to enable icon hover
  return (
    <div>
      <H3>Contact us with any question or feature request.</H3>
      <Text>Please report bugs using <a href="https://github.com/hnolcol/instantclue/issues" target="_blank"  rel="noopener noreferrer">GitHub</a> Issues</Text>
        <div style={{display:"flex",flexDirection:"row",justifyContent: "center",alignItems:"center",marginTop:"100px"}}>

        {LOGOS_FOR_DOWNLOAD.map((v,i) => {
            const SpecCmponent = DOWNLOAD_COMPS[v.key] //get function to return logo
            return(
            <motion.div 
                    className={"std-logo-container"} 
                    key = {v.key}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 1.5, delay : i * .65}}
                    variants={variants}
                    onMouseEnter={() => setTargetIcon(v.key)}
                    onClick = {v.key==="addFeature"?openEmailWindow:() => openURL(LINKS_FOR_KEYS[v.key])}>  
              {SpecCmponent()}
            </motion.div>

            )
        })}
        </div>

        <div style={{paddingTop:"30px"}}>
          <p>{targetIcon===""?null:HOVER_TEXT[targetIcon]}</p>
            
        </div>
    </div>
    
  );
}

export default ContactView



