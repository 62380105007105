import React from 'react';
import {useState} from 'react';
import {Button, H3, Text, Tooltip} from "@blueprintjs/core"
import {MacDownloadIcon, WindowsDownloadIcon,SourceCodeDownload} from "./SVGs"
import {motion} from "framer-motion"
import {openURL} from "./utils/Misc"
const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }
  
const DOWNLOAD_COMPS = {
    "mac" : MacDownloadIcon,
    "win" : WindowsDownloadIcon,
    "source": SourceCodeDownload
  }


const HOVER_TEXT = {mac:"Mac OS",win:"Windows",source:"Source code from GitHub"}
const LOGOS_FOR_DOWNLOAD = [{key:"mac"},{key:"win"},{key:"source"}]

const downloadPreffix = ["stable","dev-snap"]
const dowloadLinkByPreffx = {
  "stable" : "https://github.com/hnolCol/instantclue/releases/download/v0.11.0/",
  // "dev-build" : "/dev/InstantClue.zip",
  "dev-snap" : "https://github.com/hnolCol/instantclue/releases/latest/download/"
}
const fileName = {mac:"InstantClue-0.11.0-mac.dmg",win:"InstantClue-0.11.0-win.zip"}
const PREFIX_TOOLTIPS = {
  "stable":"Latest stable version.",
  "dev-build":"Latest development version",
  "dev-snap":"We publish snapshot builds when significant new enhancements are available or when a major bug is squashed. These builds may contain regressions as they will only have been tested by developers. Don't be surprised if things go wrong! Please report bugs to help us."}

const PREF_ICONS = {"stable":"download","dev-build":"build","dev-snap":"oil-field"}
const PREF_INTENT = {"stable":"success","dev-build":"none","dev-snap":"primary"}
  function DownloadView(props) {
    // Declare a new state variable, which we'll call "mouseOver"
    const [targetIcon, setTargetIcon] = useState("");
  return (
    <div>
      <H3>Download Instant Clue and start learning from your data.</H3>
      <Text>No requirements, just download, extract, enjoy.</Text>
        <div style={{display:"flex",flexDirection:"row",justifyContent: "center",alignItems:"center",marginTop:"140px"}}>
        
        {LOGOS_FOR_DOWNLOAD.map((v,i) => {
            const SpecCmponent = DOWNLOAD_COMPS[v.key] //get function to return logo
            return(
            <motion.div 
                    className={"std-logo-container"} 
                    key = {v.key}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 1.5, delay : i*0.55}}
                    onMouseEnter={() => setTargetIcon(v.key)}
                    onClick = {v.key === "source" ? () => openURL("https://github.com/hnolcol/instantclue"):() => openURL("https://github.com/hnolCol/instantclue/releases/latest")}
                    variants={variants}>  
            {SpecCmponent()}
        </motion.div>
            )
        })}
        </div>

        <div style={{paddingTop:"30px",color:"black"}}>
          <p>{targetIcon===""?null:HOVER_TEXT[targetIcon]}</p>
        </div>

        <div style = {{marginTop:"30px"}}>

          {downloadPreffix.map(p => {
                if (targetIcon === "" || targetIcon === "source") {return null}
                return (
                  <Tooltip key = {p}  disabled = {p !== "dev-snap"?true:false} content={<div style={{maxWidth:"500px",textAlign:"center"}}>{PREFIX_TOOLTIPS[p]}</div>}>
                    {/* <a href={DONWLOAD_LINKS[targetIcon] + dowloadLinkByPreffx[p]} target='_blank' download> */}
                    <Button style = {{width:"180px",paddingLeft:"5px"}} 
                            text = {`${p}`} 
                            rightIcon = {PREF_ICONS[p]} 
                            intent = {PREF_INTENT[p]} 
                            minimal={true}
                            // disabled = {p !== "dev-snap"?true:false}
                            onClick = {() => openURL(`${dowloadLinkByPreffx[p]}${fileName[targetIcon]}`)}
                            />
                   
                    {/* ${fileName[targetIcon]} */}
                  </Tooltip>
                )
          })
          }
          
        </div>
        
    </div>
    
  );
}

export default DownloadView



