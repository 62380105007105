import React from 'react';
import {useState} from 'react';
import {openURL} from "./utils/Misc"
import { H6, H3,H5, Text } from '@blueprintjs/core';

const INSTANT_CLUE_BLUE = "#286FA4"
const publications = [  

  {"authors":"König, T. et al.","title":"MIROs and DRP1 drive mitochondrial-derived vesicle biogenesis and promote quality control.","year":"2021","journal":"Nat Cell Biol 23, 1271–1286","link":"https://doi.org/10.1038/s41556-021-00798-4"},
  {"authors":"Lee J.G. et al.","title":"Lipid signatures reflect the function of the murine primary placentation.","year":"2021","journal":"Biology of Reproduction","link": "https://doi.org/10.1093/biolre/ioab219"},
  {"authors":"Geremaia A. et al.","title":"Activation of Akt–mTORC1 signalling reverts cancer-dependent muscle wasting.","year":"2021","journal":"Journal of Cachexia, Sarcopenia and Muscle ","link": "https://doi.org/10.1002/jcsm.12854"},
  {"authors":"Santamaria, M. E. et al.","title":"Comparative transcriptomics reveals hidden issues in the plant response to arthropod herbivores.","year":"2021","journal":" Integr Plant Biol 63, no. 2 : 312-26.","link":" https://doi.org/10.1111/jipb.13026"},
  {"authors":"Abdellatif, M. et al.","title":"Nicotinamide for the Treatment of Heart Failure with Preserved Ejection Fraction.","year":"2021","journal":"Sci Transl Med 13, no. 580","link":"https://doi.org/10.1126/scitranslmed.abd7064"},
  {"authors":"Aravamudhan S. et al.","title":"Phosphoproteomics of the developing heart identifies PERM1-An outer mitochondrial membrane protein.","year":"2021","journal":"Journal of Molecular and Cellular Cardiology","link":"https://www.sciencedirect.com/science/article/pii/S0022282821000250"},
  {"authors":"Berkova, V. et al.","title":"Arabidopsis Response to Inhibitor of Cytokinin Degradation Incyde: Modulations of Cytokinin Signaling and Plant Proteome.","year":"2020","journal":"Plants (Basel) 9, no. 11","link":"https://doi.org/10.3390/plants9111563"},
  {"authors":"Tristan-Noguero, A. et al.","title":"Novel Protein Biomarkers of Monoamine Metabolism Defects Correlate with Disease Severity.","year":"2020","journal":"Mov Disord","link":"https://doi.org/10.1002/mds.28362"},
  {"authors":"Klimpel, A. et al.","title":"Cell-Permeable Caax-Peptides Affect K-Ras Downstream Signaling and Promote Cell Death in Cancer Cells.","year":"2020","journal":"FEBS J","link":"https://doi.org/10.1111/febs.15612"},
  {"authors":"Lenco, J. et al.","title":"Sense and Nonsense of Elevated Column Temperature in Proteomic Bottom-up Lc-Ms Analyses.","year":"2020","journal":"J Proteome Res","link":"https://doi.org/10.1021/acs.jproteome.0c00479"},
  {"authors":"Park, J. E. et al.","title":"The Function of Drosophila Usp14 in Endoplasmic Reticulum Stress and Retinal Degeneration in a Model for Autosomal Dominant Retinitis Pigmentosa.","year":"2020","journal":"Biology (Basel) 9, no. 10","link":"https://doi.org/10.3390/biology9100332"},
  {"authors":"Baschieri, F. et al.","title":"Frustration of Endocytosis Potentiates Compression-Induced Receptor Signaling.","year":"2020","journal":"J Cell Sci 133, no. 17","link":"https://doi.org/10.1242/jcs.239681"},
  {"authors":"Bekes, K, et al.","title":"Saliva Proteomic Patterns in Patients with Molar Incisor Hypomineralization.","year":"2020","journal":"Sci Rep 10, no. 1","link":"https://doi.org/10.1038/s41598-020-64614-z"},
  {"authors":"Georgieva, V. S et al.","title":"Ablation of the Mirna Cluster 24 Has Profound Effects on Extracellular Matrix Protein Abundance in Cartilage.","year":"2020","journal":"Int J Mol Sci 21, no. 11","link":"https://doi.org/10.3390/ijms21114112"},
  {"authors":"Ibanez, A. et al.","title":"The Chemistry and Histology of Sexually Dimorphic Mental Glands in the Freshwater Turtle, Mauremys Leprosa.","year":"2020","journal":"PeerJ 8 ","link":"https://doi.org/10.7717/peerj.9047"},
  {"authors":"Kallabis, S. et al.","title":"High-Throughput Proteomics Fiber Typing (Profit) for Comprehensive Characterization of Single Skeletal Muscle Fibers.","year":"2020","journal":"Skelet Muscle 10, no. 1","link":"https://doi.org/10.1186/s13395-020-00226-5"},
  {"authors":"Kirchner, V. A. et al.","title":"The Evolving Microenvironment of the Human Hepatocyte: Healthy Vs. Cirrhotic Liver Vs. Isolated Cells.","year":"2020","journal":"Tissue Cell 62","link":"https://doi.org/10.1016/j.tice.2019.101310"},
  {"authors":"Kolbel, H. et al.","title":"First Clinical and Myopathological Description of a Myofibrillar Myopathy with Congenital Onset and Homozygous Mutation in Flnc.","year":"2020","journal":"Hum Mutat 41, no. 9","link":"https://doi.org/10.1002/humu.24062"},
  {"authors":"Laurien, L. et al.","title":"Autophosphorylation at Serine 166 Regulates Rip Kinase 1-Mediated Cell Death and Inflammation.","year":"2020","journal":"Nat Commun 11, no. 1","link":"https://doi.org/10.1038/s41467-020-15466-8"},
  {"authors":"Yi, L. et al.","title":"Boosting to Amplify Signal with Isobaric Labeling (Basil) Strategy for Comprehensive Quantitative Phosphoproteomic Characterization of Small Populations of Cells.","year":"2019","journal":"Anal Chem 91, no. 9 (May 7 2019): 5794-801","link":"https://doi.org/10.1021/acs.analchem.9b00024"},
  {"authors":"MacVicar, T. et al.","title":"Lipid Signalling Drives Proteolytic Rewiring of Mitochondria by Yme1l.","year":"2019","journal":"Nature 575, no. 7782","link":"https://doi.org/10.1038/s41586-019-1738-6"},
  {"authors":"Minadakis, G. et al.","title":"Protexa: A Tool for Post-Processing Proteomics Data Providing Differential Expression Metrics, Co-Expression Networks and Functional Analytics.","year":"2020","journal":"Comput Struct Biotechnol J 18","link":"https://doi.org/10.1016/j.csbj.2020.06.036"},
  {"authors":"Peters, F. et al.","title":"Murine Epidermal Ceramide Synthase 4 Is a Key Regulator of Skin Barrier Homeostasis.","year":"2020","journal":"J Invest Dermatol 140, no. 10","link":"https://doi.org/10.1016/j.jid.2020.02.006"},
  {"authors":"Pla-Martin, D. et al.","title":"Cluh Granules Coordinate Translation of Mitochondrial Proteins with Mtorc1 Signaling and Mitophagy.","year":"2020","journal":"EMBO J 39, no. 9","link":"https://doi.org/10.15252/embj.2019102731"},
  {"authors":"Reimann, L. et al.","title":"Phosphoproteomics Identifies Dual-Site Phosphorylation in an Extended Basophilic Motif Regulating Filip1-Mediated Degradation of Filamin-C.","year":"2020","journal":"Commun Biol 3, no. 1","link":"https://doi.org/10.1038/s42003-020-0982-5"},
  {"authors":"Reinoss, P. et al.","title":"Hypothalamic Pomc Neurons Innervate the Spinal Cord and Modulate the Excitability of Premotor Circuits.","year":"2020","journal":"Curr Biol","link":"https://doi.org/10.1016/j.cub.2020.08.103"},
  {"authors":"Seok, J. et al.","title":"Placenta-Derived Mesenchymal Stem Cells Restore the Ovary Function in an Ovariectomized Rat Model Via an Antioxidant Effect.","year":"2020","journal":"Antioxidants (Basel) 9, no. 7","link":"https://doi.org/10.3390/antiox9070591"},
  {"authors":"Taverna, F. et al.","title":"Biomex: An Interactive Workflow for (Single Cell) Omics Data Interpretation and Visualization.","year":"2020","journal":"Nucleic Acids Res 48, no. W1","link":"https://doi.org/10.1093/nar/gkaa332"},
  {"authors":"Tellkamp, F. et al.","title":"Proteomics of Galapagos Marine Iguanas Links Function of Femoral Gland Proteins to the Immune System.","year":"2020","journal":"Mol Cell Proteomics 19, no. 9","link":"https://doi.org/10.1074/mcp.RA120.001947"},
  {"authors":"Brandt, C. et al.","title":"Food Perception Primes Hepatic Er Homeostasis Via Melanocortin-Dependent Control of Mtor Activation.","year":"2018","journal":"Cell 175, no. 5.","link":"https://doi.org/10.1016/j.cell.2018.10.015"},
  {"authors":"Wiederstein, J. L. et al.","title":"Skeletal Muscle-Specific Methyltransferase Mettl21c Trimethylates P97 and Regulates Autophagy-Associated Protein Breakdown.","year":"2018","journal":"Cell Rep 23, no. 5","link":"https://doi.org/10.1016/j.celrep.2018.03.136"},
  {"authors":"Misra, B. B. et al.","title":"Tools and Resources for Metabolomics Research Community: A 2017-2018 Update.","year":"2019","journal":"Electrophoresis 40, no. 2","link":"https://doi.org/10.1002/elps.201800428"}
]

function PublicationView(props) {
  const [targetCitation, setTargetCitation] = useState("");
    // Declare a new state variable, which we'll call "mouseOver"
  return (
    <div >
        <H3>Instant Clue in action.</H3>
        <Text>Any acknowledgment is highly appreciated.</Text>
        <Text>Find below some publications that cited Instant Clue.</Text>
        <div style={{display:"flex",flexDirection:"row",justifyContent: "center",alignItems:"center",marginTop:"50px", flexWrap:"wrap",cursor:"pointer"}}>
          <div 
              className={targetCitation==="IC"?"publicationBox hover":"publicationBox"} 
              onClick = {() => openURL("https://www.nature.com/articles/s41598-018-31154-6")} 
              onMouseEnter = {() => setTargetCitation("IC")}
              onMouseLeave = {() => setTargetCitation("")}>
              <H5 style={{color:INSTANT_CLUE_BLUE}}>{"Instant Clue: A Software Suite for Interactive Data Visualization and Analysis"}</H5>
              <Text>{"Nolte H. et al"}</Text>
              <Text >{"Scientific Reports 8 (2020)"}</Text>
            </div>
            <div 
              className={targetCitation==="IC2"?"publicationBox hover":"publicationBox"} 
              onClick = {() => openURL("https://www.nature.com/articles/s41598-018-31154-6")} 
              onMouseEnter = {() => setTargetCitation("IC2")}
              onMouseLeave = {() => setTargetCitation("")}>
              <H5 style={{color:INSTANT_CLUE_BLUE}}>{"Extending the Instant Clue Software by Visual Analytics Tools"}</H5>
              <Text>{"Nolte H. et al"}</Text>
              <Text >{"Publication pending.."}</Text>
            </div>
        </div>
        <div style={{display:"flex",flexDirection:"row",justifyContent: "center",alignItems:"center",marginTop:"100px", flexWrap:"wrap",cursor:"pointer"}}>
          {publications.map(v => {
            const mouseOverMe = targetCitation===v.title
            return(
            <div 
              key = {v.authors}
              className={mouseOverMe?"publicationBox":"publicationBox"} 
              onClick = {() => openURL(v.link)} 
              onMouseEnter = {() => setTargetCitation(v.title)}
              onMouseLeave = {() => setTargetCitation("")}>
              <H6 style={{color:mouseOverMe?INSTANT_CLUE_BLUE:"#3d3d3d"}}>{v.title}</H6>
              <H6>{v.authors}</H6>
              <div style={{paddingTop:"15px"}}>
              <Text >{`${v.journal} (${v.year})`}</Text>
              </div>
            </div>
            )
          })}
        </div>
    </div>
    
  );
}

export default PublicationView



