import React from "react";
import {
        Collapse, Callout} from "@blueprintjs/core"
import {useState} from 'react';
import {MenuButton} from "./MenuButton"

export function CollapsableText(props) {
       
    const {title, openAtRender, id, height, ...rest} = props
   
    const [isOpen, setIsOpen] = useState(false);
    const toggleState = () => setIsOpen(!isOpen);
    return(

            <div style = {{paddingLeft:"8px",paddingRight:"8px"}}> 
                <MenuButton buttonText = {props.title} toggleFunc = {toggleState} maxWidth = "100%" minWidth = {"96%"} {...rest}/>
                <Collapse isOpen = {isOpen} transitionDuration = {700}>
                    <Callout style = {{overflowY:"scroll",height:height,maxWidth:"100%",overflowX:"hidden"}}>

                        {props.content}

                    </Callout>
                </Collapse>
            </div>
            )
}
