import React from 'react';
import {useState} from 'react';
import { H5, Divider, ButtonGroup, Dialog, Tooltip, Button, Navbar, Alignment} from "@blueprintjs/core"

import {InstantClueIcon} from "./components/SVGs"
import {MenuButton} from "./components/utils/MenuButton"
import HomeView from "./components/Home"
import DownloadView from "./components/Download"
import ContactView from "./components/Contact"
import TutorialView from "./components/Tutorial"
import PublicationView from "./components/Publications"

import './App.css';

const __VERSION__ = "0.11.2"
const SHOW_MAINTENANCE_DIALOG = false
const MENU_OPTIONS = [{"text":"home"},{"text":"download"},{"text":"tutorial"},{"text":"contact"},{"text":"publications"}]

function App() {
  const [mainView, setMainView] = useState("home");
  return (
    

    <div className={"wrapper"}>
    
    <div className={"left-container"}>

      <div className={"logo-container"}>
          <div  style={{float:"left",width:"50%"}}>
          <InstantClueIcon />
          </div>
          <div style = {{marginTop:"20px"}}>
            <H5>InstantClue</H5>
            
            <a href = {"https://github.com/hnolCol/instantclue"} target = "_blank" rel="noopener noreferrer">
                <p>View on GitHub</p>
            </a>
              <p>v.{__VERSION__}</p>
  
          </div>
      </div>

      <div className={"sideMenu-container"}>
            <ButtonGroup minimal = {true} 
                            vertical = {true} 
                            fill = {true}
                            >
                  {MENU_OPTIONS.map(m => {
                    return(
                      <div key = {m.text}>
                        <Divider/>
                        <div>
                        <MenuButton style = {{marginTop:"2px"}} buttonText = {m.text} callback = {setMainView} maxWidth = {180} minWidth = {"150px"}/>
                        </div>
                      </div>
                    )
                  })}
                 <Divider/>
                
            </ButtonGroup>
       </div>
    </div>

    <div className={"right-container"}>  
      <Dialog title = "Instant Clue" isOpen = {SHOW_MAINTENANCE_DIALOG} isCloseButtonShown={false}>
      
      <div style={{marginLeft:"10px",marginTop:"20px"}}>
        <H5>Information - InstantClue goes PyQt5</H5>
        <p>We are building a new website at the moment. </p>
        <p>Additionally, a new version of Instant Clue will be available soon!</p>
        <p>Beta version source code is already at GitHub.</p>
        
        <p>Stay tuned. An alert will be fire in your old InstantClue version.</p>
        <p>Your Instant Clue Team</p>
        <p>If you still want to download the tk/tcl version 0.5.2, find the links below</p>
        
        <ButtonGroup style={{paddingLeft:"130px"}}>
          <Tooltip content={<p>Please install Tk/Tcl version 5.8 for Mac</p>}>
          <a href = {"http://www.instantclue.uni-koeln.de/files/InstantClue_mac.zip"}>Mac OS</a>
          </Tooltip>
          <a href = {"http://www.instantclue.uni-koeln.de/files/InstantClue_windows.zip"}>Windows</a>
        </ButtonGroup>

      </div>
      </Dialog>
      <Navbar fixedToTop={true} >
      <Navbar.Group align={Alignment.RIGHT}>
        <Navbar.Heading>InstantClue</Navbar.Heading>
        <Navbar.Divider/>
        <div style={{padding: "3px"}}> 
        <a href={"https://github.com/hnolcol/instantclue/releases/latest"}>
          <Button rightIcon="download" text = {"Download"}/>
        </a>
        </div>
        <div style={{padding: "3px"}}>          
        <a href={"https://github.com/hnolcol/instantclue/wiki"}>
          <Button intent = {"success"} rightIcon="learning" text={"Docs"}/>
        </a>
        </div>
        <div style={{padding: "3px"}}> 
        <a href={"https://www.youtube.com/channel/UCjSfodDjhCMY2bw9_i6VOXA/videos"}>
          <Button intent = {"danger"} rightIcon="video" text= {"Videos"}/>
        </a>
        </div>
        <Navbar.Divider/>
        <a href={"https://app.instantclue.de"}>
          <Button disabled={"true"} intent="primary" rightIcon="graph">Open Share App</Button>
        </a>
        </Navbar.Group>
      </Navbar>
      {mainView === "home"?<HomeView setMainView = {setMainView}/>:
          mainView==="download"?<DownloadView/>:
          mainView==="contact"?<ContactView/>:
          mainView==="tutorial"?<TutorialView/>:
          mainView==="publications"?<PublicationView/>:
          <H5>mainVi1ew</H5>}
      
      
    </div>
    
   
   
    </div>
    
  );
}

export default App;
