import React from 'react';

import {CollapsableText} from "./utils/CollapsableText"
import {VideoTutorialIcon} from "./SVGs"
import {motion} from "framer-motion"
import { H3, Text } from '@blueprintjs/core';

import YouTube from 'react-youtube';
const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }
  
const DOWNLOAD_COMPS = {
    "vidTutorial" : VideoTutorialIcon,
  }
const LOGOS_FOR_DOWNLOAD = [{key:"vidTutorial"}]


const FAQ = [
            {
              q:"How to upload data?",
              v:undefined,
              t:"Supported formats are: .txt and .xslx. To upload data you have several options: a) Drag & Drop the file from the finder/explorer onto tree view or the upload button. b) Select the file from hard drive using the upload button. c) Copy data from spreadsheet and paste (CMD/CTRL+V) after selecting the treeview. Please note that copied data should have column headers. In the video below all options are shown."},
            {q:"How to save and open a session?",
              v:undefined,
              t:"Coming soon."},
            {q:"How export a graph to a main figure?",
              v:undefined,t:"Once you created a chart, right-click on a white-space will create a context menu 'To Main figure'. If a main figure exists (botton bottom-right) and an axis was created, you will be able to select a specific axis."},
            {q:"How to perform pairwise comparisons?",v:undefined,t:"Coming soon."},
            {q:"How to plot a volcano plot?",v:undefined,t:"A volcano plot is a widely used type of scatter plot to visualize a comparison versus a -log10 transformed p-value. In the video below, we demonstrate how you can calculate the t-test statistic. Additionally, we demonstrate how you can highlight significantly changed features by color and size. The tooltip option enables quick screening of changed candidates. The volcano plot visualization works well with the novel widgets: QuickSelect and LiveGraph."},
            {q:"How to use dimensional reduction techniques?",v:undefined,t:"Coming soon."}
            ]

const opts = {
              height: '390',
              width: '640',
              playerVars: {
                autoplay: 0,
              },
            };



function TutorialView(props) {
    // Declare a new state variable, which we'll call "mouseOver"
  return (
    <div style = {{minHeight:"2000px"}}>
        <H3>Get started to explore your data.</H3>
        <Text>Please contact us for feature requests.</Text>
        <div style={{display:"flex",flexDirection:"row",justifyContent: "center",alignItems:"center",marginTop:"100px"}}>
        {LOGOS_FOR_DOWNLOAD.map((v,i) => {
            const SpecCmponent = DOWNLOAD_COMPS[v.key] //get function to return logo
            return(
            <motion.div 
                    className={"std-logo-container"} 
                    key = {v.key}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 1.5, delay : i}}
                    variants={variants}>  
            {SpecCmponent()}
        </motion.div>
            )
        })}
        </div>

        <motion.div initial="hidden"
                    animate="visible"
                    transition={{ duration: 1.5, delay : 0.75}}
                    style={{paddingTop:"4%",width:"90%",paddingLeft:"10%",textAlign:"center"}}
                    variants={variants}>

            <div style = {{paddingLeft:"10%",paddingRight:"10%"}}>
            <Text>
              In this section you will find short (2 min max) videos to frequently asked questions.
              Please contact us if you are missing some aspects.
            </Text>
            <Text>
              Please check out the <a href={"https://github.com/hnolcol/instantclue/wiki"} target="_blank" rel="noopener noreferrer">GitHub Wiki</a> for more details about InstantClue and specific settings.
            </Text>
            </div>
        <div style={{paddingTop:"2%"}}>
        {FAQ.map(v => {
          const CONTENT =  <div style = {{maxWidth:"100%",minHeight:"300px"}}>
                              <div style = {{textAlign:"justify",marginBottom:"25px",marginTop:"25px"}}>
                              <Text>{v.t}</Text>
                              </div>
                              {v.v !== undefined? 
                                <YouTube videoId={v.v} opts={opts}/>
                                : null}
                            </div>
          return(
          <CollapsableText key = {v.q} title = {v.q} isOpen = {true} content = {CONTENT} border={true} height = "600px"/>
          )
        })
      }
      </div>
        
      </motion.div>
    </div>
    
  );
}

export default TutorialView



